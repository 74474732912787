






















































































































































































































































































































































.payment-methods {
  .disabled-payment {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
