












































































.el-popup-parent--hidden {
  padding-right: 0 !important;
}

.further-payment-methods-drawer {
  .el-drawer__header {
    margin-bottom: 0 !important;
  }
}
